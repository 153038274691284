<script>
export default {
  data() {
    return {
      aboutMeBgImage: require("@/assets/about-number-bg.jpg"),
    };
  },
};
</script>

<template>
  <div>
    <!-- About Me Section Start-->
    <section class="container mt-5">
      <div class="row">
        <div class="col-md-7 col-sm-12">
          <h1>About Me</h1>
          <p>
            Hey there! I'm Monayem Islam, and I'm not your average web
            developer. You can call me a
            <span style="text-decoration-line: underline"
              >full-stack developer</span
            >, a
            <span style="text-decoration-line: underline"
              >pull-stack developer</span
            >, or even a
            <span style="text-decoration-line: underline"
              >Fool-Stack developer</span
            >
            for laughs 🤪. But above all, I consider myself a
            <span style="color: #ff4c60">problem solver</span>.

            <br /><br />

            If you're in search of a highly passionate, adaptable, and dedicated
            web application developer, your search ends here. I possess the
            skills and expertise to transform your ideas into visually
            captivating and functional realities. Let's tackle your challenges
            together!
          </p>
        </div>
        <div class="col-md-5 col-sm-12 image-parent-section text-center">
          <img
            src="./../assets/monayem.png"
            alt="Monayem Islam"
            class="img-fluid my-image"
          />
        </div>
      </div>
    </section>
    <!-- About Me Section End-->

    <!-- Section Experience -->
    <section id="experience" class="py-5 bg-white">
      <div class="container">
        <!-- Section Title -->
        <h2 class="section-title text-center mb-4">
          My academic journey and professional adventures
        </h2>

        <div class="spacer" data-height="60"></div>

        <div class="row">
          <div
            class="col-md-6"
            data-aos="fade-up-right"
            data-aos-duration="1000"
          >
            <!-- Timeline Wrapper -->
            <div class="timeline edu bg-white rounded shadow p-4 mb-4">
              <!-- Timeline Item 1 -->
              <div class="timeline-container">
                <div>
                  <h5>
                    <i class="fas fa-graduation-cap"></i>
                    <i></i> M.Sc in Information & Communication Technology
                  </h5>
                  <span>Passing Year: 2022</span>
                  <p>Islamic University, Bangladesh</p>
                </div>
              </div>

              <!-- Timeline Item 2 -->
              <div class="timeline-container">
                <div>
                  <h5>
                    <i class="fas fa-graduation-cap"></i>
                    <i></i> B.Sc in Information & Communication Technology
                  </h5>
                  <span>Passing Year: 2021</span>
                  <p>Islamic University, Bangladesh</p>
                </div>
              </div>

              <!-- Timeline Item 3 -->
              <div class="timeline-container">
                <div>
                  <h5>
                    <i class="fas fa-graduation-cap"></i>
                    <i></i> Higher Secondary Certificate in Science Group
                  </h5>
                  <span>Passing Year: 2015</span>
                  <p>
                    Nawabganj Govt. College, Chapainawabganj-6300, Bangladesh
                  </p>
                </div>
              </div>
              <!--Timeline Item 4-->
              <div class="timeline-container">
                <div>
                  <h5>
                    <i class="fas fa-graduation-cap"></i>
                    <i></i> Secondary School Certificate in Science Group
                  </h5>
                  <span>Passing Year: 2013</span>
                  <p>
                    Harimohan Govt. High School, Chapainawabganj-6300,
                    Bangladesh
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-6"
            data-aos="fade-up-left"
            data-aos-duration="1000"
          >
            <!-- Responsive Spacer -->
            <div class="spacer d-md-none d-lg-none" data-height="30"></div>

            <!-- Timeline Wrapper -->
            <div class="timeline exp bg-custom-child rounded shadow p-4 mb-4">
              <!-- Timeline Item 1 -->
              <div class="timeline-container">
                <div>
                  <h5>
                    <i class="fas fa-briefcase"></i>
                    <i></i> Web Developer
                  </h5>
                  <span>2021 - Present</span>
                  <p>
                    <a
                      href="https://www.nybsys.com"
                      target="_blank"
                      class="text-dark text-decoration-none"
                      >Nybsys Inc.</a
                    >
                  </p>
                </div>
              </div>

              <!-- Timeline Item 2 -->
              <div class="timeline-container">
                <div>
                  <h5>
                    <i class="fas fa-briefcase"></i>
                    <i></i> Software Developer
                  </h5>
                  <span>2020 - 2021</span>
                  <p>
                    <a
                      href="https://www.sqgc.com"
                      target="_blank"
                      class="text-dark text-decoration-none"
                      >SQ Group</a
                    >
                  </p>
                </div>
              </div>

              <!-- Timeline Item 3 -->
              <div>
                <div>
                  <h5>
                    <i class="fas fa-briefcase"></i>
                    <i></i> Sotware Developer (Trainee)
                  </h5>
                  <span>2020-2020</span>
                  <p>BITM and Leads Training & Consulting Ltd</p>
                </div>
              </div>
              <!-- Timeline Item 4 -->
              <div>
                <div>
                  <h5>
                    <i class="fas fa-briefcase"></i>
                    <i></i> Web Developer
                  </h5>
                  <span>2019-2020</span>
                  <p>
                    IEEE IU Student Branch & ICE Innovation Lab, Islamic
                    University
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Back End Start-->
    <div class="container pt-5">
      <h2 class="text-center pb-5">Mastery & Proficiencies</h2>
      <section class="my-2">
        <div class="row pb-4">
          <div class="col-md-3">
            <h3 class="fw-normal">Back-end Skills</h3>
          </div>
          <div class="col-md-3">
            <p>Frameworks & CMS</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <span class="skill-item">Laravel</span>
              <span class="skill-item">CodeIgniter</span>
              <span class="skill-item">Wordpress</span>
            </div>
          </div>
          <div class="col-md-3">
            <p>Languages</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span class="skill-item">PHP</span>
              <span class="skill-item">SQL</span>
            </div>
          </div>
          <div class="col-md-3">
            <p>DBMS & Others</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <span class="skill-item">MySQL</span>
              <span class="skill-item">PostgreSQL</span>
              <span class="skill-item">MS SQL Server</span>
              <span class="skill-item">Amazon RDS</span>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Back end end-->

    <!-- Front End Start -->
    <div class="container">
      <section class="my-2">
        <div class="row pb-4">
          <div class="col-md-3">
            <h3 class="fw-normal">Front-end Skills</h3>
          </div>
          <div class="col-md-3">
            <p>Frameworks</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <span class="skill-item">Vue</span>
              <span class="skill-item">Angular</span>
            </div>
          </div>
          <div class="col-md-3">
            <p>Languages</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span class="skill-item">TypeScript</span>
              <span class="skill-item">JavaScript</span>
              <span class="skill-item">HTML</span>
              <span class="skill-item">CSS</span>
            </div>
          </div>
          <div class="col-md-3">
            <p>Libraries & Others</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <span class="skill-item">Bootstrap</span>
              <span class="skill-item">Tailwind</span>
              <span class="skill-item">JQuery</span>
              <span class="skill-item">AJAX</span>
              <span class="skill-item">JSON</span>
              <span class="skill-item">Chart Js</span>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Front end end -->

    <!-- Technology Start -->
    <div class="container">
      <section class="my-2">
        <div class="row pb-4">
          <div class="col-md-3">
            <h3 class="fw-normal">Services & Tools</h3>
          </div>
          <div class="col-md-3">
            <p>VCS & PMT</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <span class="skill-item">Git</span>
              <span class="skill-item">Github</span>
              <span class="skill-item">Bitbucket</span>
              <span class="skill-item">JIRA</span>
            </div>
          </div>
          <div class="col-md-3">
            <p>Tools</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span class="skill-item">Postman</span>
              <span class="skill-item">Swagger</span>
              <span class="skill-item">XAMPP</span>
              <span class="skill-item">WAMP</span>
              <span class="skill-item">Cpanel</span>
            </div>
          </div>
          <div class="col-md-3">
            <p>Others</p>
            <div
              class="d-flex flex-wrap"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <span class="skill-item">AWS</span>
              <span class="skill-item">Internet of Things(IoT)</span>
              <span class="skill-item">Linux</span>
              <span class="skill-item">Windows</span>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- Technology end -->

    <!--Number that tell me about myself start-->
    <div
      class="background-container-about-bg d-flex justify-content-center align-items-center"
      :style="{ backgroundImage: 'url(' + aboutMeBgImage + ')' }"
    >
      <div class="container" data-aos="fade-up" data-aos-duration="1000">
        <h2 class="section-title text-center pb-3 pt-2">The Data Speaks</h2>

        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-3 col-sm-6">
            <!-- fact item 1 -->
            <div class="fact-item text-center">
              <div class="card text-white card-color-4 my-3">
                <div class="card-body">
                  <i class="fas fa-fire fa-2x mb-2"></i>
                  <!-- Font Awesome icon -->
                  <h3 class="mb-0 mt-0 number"><em class="count">30+</em></h3>
                  <p class="mb-0">Projects completed</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <!-- fact item 2 -->
            <div class="fact-item text-center">
              <div class="card card-color-3 text-white my-3">
                <div class="card-body">
                  <i class="fas fa-coffee fa-2x mb-2"></i>
                  <!-- Font Awesome icon -->
                  <h3 class="mb-0 mt-0 number"><em class="count">1000+</em></h3>
                  <p class="mb-0">Cups of coffee</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <!-- fact item 3 -->
            <div class="fact-item text-center">
              <div class="card text-white my-3 card-color-2">
                <div class="card-body">
                  <i class="fas fa-users fa-2x mb-2"></i>
                  <!-- Font Awesome icon -->
                  <h3 class="mb-0 mt-0 number"><em class="count">20+</em></h3>
                  <p class="mb-0">Satisfied clients</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <!-- fact item 4 -->
            <div class="fact-item text-center">
              <div class="card text-white my-3 card-color-1">
                <div class="card-body">
                  <i class="fas fa-trophy fa-2x mb-2"></i>
                  <!-- Font Awesome icon -->
                  <h3 class="mb-0 mt-0 number"><em class="count">4+</em></h3>
                  <p class="mb-0">Years of Experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Numbers that tell me about myself end-->

    <!--achievement start-->
    <section class="container py-3">
      <h2 class="text-center">Achievement</h2>
      <div class="row py-5">
        <div class="col-md-6" data-aos="fade-right" data-aos-duration="1000">
          <div class="fs-5 pb-3">
            Runner-up, Project Showcasing Event, SUST Tech Fest-2019
          </div>
          <p>
            At the SUST Tech Fest, organized by Shahjalal University of Science
            and Technology in Sylhet, we had a fantastic experience. Picture
            this: 1200 participants from 54 universities, all gathered to
            celebrate technology and innovation.<br /><br />

            Our team, Team Variable-6, made quite an entrance with our project –
            the "Home Automation System Using the Internet of Things." It was
            all about revolutionizing home automation through cutting-edge IoT
            technology. People were fascinated by how our project could
            transform their daily lives.<br /><br />

            The festival itself was like a melting pot of creativity and ideas.
            It showcased the power of innovation and technology. Our
            participation was a testament to what technology can achieve, and we
            were proud to be part of this amazing event. It's events like these
            that make us excited about the future of technology!
          </p>
        </div>
        <div class="col-md-6" data-aos="fade-left" data-aos-duration="1000">
          <img
            src="../assets/works/acheivement.jpg"
            alt="Achievement Image"
            class="img-fluid rounded"
          />
        </div>
      </div>
    </section>

    <!--achievement end-->

    <div class="bg-white">
      <!-- CTA Section -->
      <section class="cta-section py-5 container">
        <div class="row">
          <!-- First Column (Title and Text) -->
          <div class="col-md-6">
            <h2 class="section-title">Want to Know More?</h2>
            <p class="section-text">
              If you're curious to know more about me, I'm offering you a
              coffee. Let's connect and dive into a conversation!
            </p>
          </div>
          <!-- Second Column (CTA Button) -->
          <div
            class="col-md-6 d-flex justify-content-center align-items-center"
          >
            <router-link to="/contact" class="btn btn-lg cta-btn-about-me"
              >Get In Touch</router-link
            >
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.image-parent-section {
  position: relative;
  /* Add a height or content to the parent if not already set */
  height: 300px;
  /* Adjust the height as needed */
}

.my-image {
  width: 250px;
  height: auto;
  position: absolute;
  bottom: 0;
}
.background-container-about-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 300px;
}
.fact-item i {
  font-size: 2rem;
}

/* Hover effect */
.fact-item:hover .card {
  transform: scale(1.1); /* Scale up on hover */
  transition: transform 0.3s ease; /* Smooth transition */
}
.card-color-1 {
  background: hsla(321, 42%, 48%, 1);

  background: linear-gradient(
    90deg,
    hsla(321, 42%, 48%, 1) 0%,
    hsla(343, 80%, 65%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(321, 42%, 48%, 1) 0%,
    hsla(343, 80%, 65%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(321, 42%, 48%, 1) 0%,
    hsla(343, 80%, 65%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#AD4689", endColorstr="#ED5C86", GradientType=1 );
}
.card-color-2 {
  background: hsla(279, 95%, 49%, 1);

  background: linear-gradient(
    90deg,
    hsla(279, 95%, 49%, 1) 0%,
    hsla(295, 96%, 50%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(279, 95%, 49%, 1) 0%,
    hsla(295, 96%, 50%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(279, 95%, 49%, 1) 0%,
    hsla(295, 96%, 50%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#A106F4", endColorstr="#E707FA", GradientType=1 );
}
.card-color-3 {
  background: hsla(185, 64%, 51%, 1);

  background: linear-gradient(
    90deg,
    hsla(185, 64%, 51%, 1) 0%,
    hsla(277, 74%, 24%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(185, 64%, 51%, 1) 0%,
    hsla(277, 74%, 24%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(185, 64%, 51%, 1) 0%,
    hsla(277, 74%, 24%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#30C5D2", endColorstr="#471069", GradientType=1 );
}
.card-color-4 {
  background: hsla(232, 73%, 65%, 1);

  background: linear-gradient(
    90deg,
    hsla(232, 73%, 65%, 1) 0%,
    hsla(279, 33%, 48%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(232, 73%, 65%, 1) 0%,
    hsla(279, 33%, 48%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(232, 73%, 65%, 1) 0%,
    hsla(279, 33%, 48%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#6274E7", endColorstr="#8752A3", GradientType=1 );
}
.cta-btn-about-me {
  background-color: #ff4c60;
  color: #ffffff;
}
.cta-btn-about-me:hover {
  color: #ff4c60;
  background-color: #ffffff;
  border: 1px solid #ff4c60;
}
.skill-item {
  background-color: #ff4c60;
  padding: 2px 6px;
  margin: 2px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
}
/* .table-col-20 {
  width: 20%;
}
.table-col-30 {
  width: 30%;
}
.table-col-50 {
  width: 50%;
}
@media (max-width: 576px) {
  .table-col-20 {
    font-size: 16px;
  }
  .table-col-30 {
    font-size: 14px;
  }
  .table-col-50 {
    font-size: 10px;
  }
} */
</style>