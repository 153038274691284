<template>
  <div class="container my-5 contact-custom-container">
    <div class="row g-5">
      <!-- Contact Form Column -->
      <div class="col-md-6">
        <h2 class="display-6">Contact</h2>
        <div style="width: 100%">
          <iframe
            width="100%"
            height="300"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=Mirpur-6,%20Dhaka+(Monayem%20Islam%20Tamal)&amp;t=h&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ><a href="https://www.maps.ie/population/"
              >Find Population on Map</a
            ></iframe
          >
        </div>
      </div>

      <!-- Map and Contact Details Column -->
      <div class="col-md-6">
        <!-- Contact Details -->
        <h2 class="display-6 mt-sm-5">Contact Details</h2>
        <p>
          If you prefer to reach me through other means, you can use the
          following contact details:
        </p>
        <p><strong>Email:</strong> contact@monayemislam.me</p>
        <p><strong>Phone:</strong> +88 01723098811</p>

        <!-- Social Media Links -->
        <h2 class="display-6 mt-4">Connect Me</h2>
        <div class="d-flex">
          <a
            href="https://www.linkedin.com/in/monayemislam/"
            target="_blank"
            class="me-3"
          >
            <i class="fab fa-linkedin fa-2x"></i>
          </a>
          <a
            href="https://www.facebook.com/monayem.islam.7"
            target="_blank"
            class="me-3"
          >
            <i class="fab fa-facebook-square fa-2x"></i>
          </a>
          <a
            href="https://twitter.com/monayemislam"
            target="_blank"
            class="me-3"
          >
            <i class="fab fa-twitter-square fa-2x"></i>
          </a>
          <a
            href="https://www.hackerrank.com/monayemislam"
            target="_blank"
            class="me-3"
          >
            <i class="fab fa-hackerrank fa-2x"></i>
          </a>
          <a
            href="https://stackoverflow.com/users/11581254/monayem-islam"
            target="_blank"
            class="me-3"
          >
            <i class="fab fa-stack-overflow fa-2x"></i>
          </a>
          <a
            href="https://monayemislam.medium.com/"
            target="_blank"
            class="me-3"
          >
            <i class="fab fa-medium fa-2x"></i>
          </a>
          <a href="https://github.com/monayemislam" target="_blank">
            <i class="fab fa-github fa-2x"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contact-btn {
  color: #ff4c60;
  background-color: #ffffff;
  border: 1px solid #ff4c60;
}
.contact-btn:hover {
  background-color: #ff4c60;
  color: #ffffff;
}

/* For Desktop */
@media screen and (min-width: 1024px) {
  .contact-custom-container {
    height: calc(100vh - 135px);
  }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .contact-custom-container {
    height: auto;
  }
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  .contact-custom-container {
    height: auto;
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .contact-custom-container {
    height: auto;
  }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .contact-custom-container {
    height: auto;
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
}
</style>