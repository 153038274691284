import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomeView.vue'
import About from '@/views/AboutView.vue'
import Contact from '@/views/ContactView.vue'
import Services from '@/views/ServicesView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/services',
        name: 'Services',
        component: Services
    },
    {
        path: '/privacy-policy',
        name: 'Privacy-Policy',
        component: PrivacyPolicyView
    },
 
]

const router = createRouter({ history: createWebHistory(), routes })
export default router
