<template>
  <div class="container-home-box">
    <div class="hero-content">
      <p class="greeting">Hello 👋 I am</p>
      <h1 class="name">MD MONAYEM ISLAM</h1>
      <p class="description">I'm a passionate full stack developer with expertise in crafting both backend and frontend solutions. I strive to create elegant, high-performance web applications.</p>
      <div class="social-links">
        <a href="https://www.linkedin.com/in/monayemislam" target="_blank" class="social-icon"><i class="fab fa-linkedin fa-2x"></i></a>
        <a href="https://stackoverflow.com/users/11581254/monayem-islam" target="_blank" class="social-icon"><i class="fab fa-stack-overflow fa-2x"></i></a>
        <a href="https://github.com/monayemislam" target="_blank" class="social-icon"><i class="fab fa-github fa-2x"></i></a>
        <a href="https://monayemislam.medium.com" target="_blank" class="social-icon"><i class="fab fa-medium fa-2x"></i></a>
        <a href="https://www.facebook.com/monayem.islam.7" target="_blank" class="social-icon"><i class="fab fa-facebook-square fa-2x"></i></a>
        <a href="https://twitter.com/monayemislam" target="_blank" class="social-icon"><i class="fab fa-twitter-square fa-2x"></i></a>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Apply light background color to the whole page */
html, body {
  height: 100%;
  margin: 0;
  background-color: #f8f9fa; /* Light background color */
}

.container-home-box {
  height: calc(100vh - 86px); /* Adjust height to account for navbar */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px; /* Ensure some padding on smaller screens */
}

.hero-content {
  text-align: center;
  max-width: 850px;
  width: 100%;
}

.greeting {
  font-size: 2rem; /* Larger font size */
  margin-bottom: 10px;
}

.name {
  font-size: 4rem; /* Larger font size */
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.description {
  font-size: 1.5rem; /* Larger font size */
  margin-bottom: 20px;
}

.social-links {
  display: flex;
  justify-content: center;
}

.social-icon {
  margin: 0 15px; /* Increased margin for better spacing */
  color: #333;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #007bff; /* Change to your desired hover color */
}
</style>
